<template>
  <div>
    <!-- download92724CDBA550E8FEE1F2624C6DB09BB3.swf -->
    <!-- pic0165170797DDABB22A75B30679531E19.jpg -->
    <img class="rounded img-large" src="">
    <p>
      <b>LEGO Club Day</b><br>
      LEGOLAND®, Germany<br>
      May 17, 2009
    </p>
    <p>
      The LEGO Universe team is very excited about the upcoming LEGO Club Day in Germany.  Last year over 1,400 fans attended and this year we expect even more! The event will be jam-packed with loads of exciting events including:
    </p>
    <p>
      - Exclusive location for LEGO Club members only!
      <br>- Tips &amp; tricks from the LEGOLAND model builders
      <br>- Drawing for special prizes – win a tour behind the scenes of Miniland and to the model builder’s studio!
      <br>- Building competitions
      <br>- Preview of LEGO products
    </p>
    <p>
      And much, much more!
    </p>
  </div>
</template>
